@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.periods {
  font-family: var.$text-font;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  display: flex;
  gap: 8px;
  margin: 34px 0;
  align-items: center;

  @include mix.media(
    (
      md: (
        flex-direction: column-reverse,
        align-items: flex-start,
        margin: 16px 0,
      ),
    )
  );

  &Buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.period {
  @include mix.TextB2Semibold;

  cursor: pointer;
  user-select: none;

  box-sizing: border-box;
  width: fit-content;
  padding: 8px 12px;

  color: var.$graphite-100;
  white-space: nowrap;

  background: var.$gray-80;
  border: 2px solid var.$gray-80;

  transition: 0.3s ease-in-out;
  transition-property: background, color, border-color;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(8) mix.vw(12),
      ),
      md: (
        padding: 8px 12px,
      ),
    )
  );

  &:hover {
    background: var.$green-main-50;
    border-color: var.$green-main-50;
  }

  &:focus-visible {
    outline: 2px solid var.$information-100;
  }

  &Disabled {
    pointer-events: none;
    opacity: 0.3;

    &:focus-visible {
      outline: none;
    }
  }

  &Active {
    background: var.$green-main-100;
    border-color: var.$green-main-100;

    &:hover {
      background: var.$green-main-hover;
      border-color: var.$green-main-hover;
    }
  }
}
