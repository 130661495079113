@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.card {
  width: 280px;
  height: 100%;
  min-height: 478px;
  overflow: visible;

  @media (width <= 1216px) {
    width: auto;
  }
}

.cardDescription {
  height: 40px;
}

.characteristics {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cardRow {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.tag {
  position: relative;
}

.button {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;

  &Main {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.cardPrice {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.cardCheck {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tagFreeTier {
  width: fit-content;
}

.tagFree {
  width: calc(100% - 8px);
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: flex-end;
}

.char {
  color: ste.$sys-neutral-text-main;
}

.hint {
  width: fit-content;
}
