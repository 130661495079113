@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  position: relative;
  padding: 56px 0;
  padding-block: 56px;
  box-sizing: border-box;
  height: 100%;
  min-height: 368px;

  @include mix.media(
    (
      1280: (
        padding-block: mix.vw(56),
        min-height: mix.vw(368),
      ),
      md: (
        min-height: 358px,
        padding: 24px 0,
      ),
      sm: (
        min-height: 296px,
        padding: 24px 0,
      ),
    )
  );

  &.whiteText {
    .title,
    .description {
      color: var.$gray-0;
    }
  }
}
.wrapper {
  position: relative;

  @include mix.media(
    (
      1280: (),
      md: (
        padding-right: 0,
      ),
      sm: (
        padding-right: 24px,
        height: 100%,
      ),
    )
  );
}

.grid {
  display: grid;
  grid-template-columns: 653px 1fr;
  gap: 20px;

  @include mix.media(
    (
      1280: (
        grid-template-columns: mix.vw(653) 1fr,
        gap: mix.vw(20),
      ),
      md: (
        gap: 20px,
        grid-template-columns: 388px 1fr,
      ),
      758: (
        grid-template-columns: 300px 1fr,
      ),
      sm: (
        grid-template-columns: 100%,
        gap: 32px,
      ),
    )
  );
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 4;
  min-height: 256px;
  box-sizing: border-box;

  @include mix.media(
    (
      1280: (
        min-height: 256px,
      ),
      md: (
        min-height: 270px,
      ),
      sm: (
        min-height: 280px,
      ),
    )
  );

  br {
    @include mix.media(
      (
        md: (
          display: none,
        ),
      )
    );
  }
}

.title {
  @include mix.TitleH1;
}

.description {
  @include mix.TextB1Regular;
  margin: 16px 0 32px;

  @include mix.media(
    (
      1280: (
        margin: mix.vw(16) 0 mix.vw(32),
      ),
      md: (
        margin: 16px 0 32px,
      ),
    )
  );
}

.button {
  margin-top: auto;

  @include mix.media(
    (
      sm: (
        width: 100%,
      ),
    )
  );
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-height: 328px;
  object-fit: cover;
  object-position: center;

  aspect-ratio: 541 / 328;

  @include mix.media(
    (
      1280: (
        object-position: left 0,
        max-height: mix.vw(328),
      ),
      md: (
        right: 0,
        top: unset,
        left: unset,
        transform: unset,
        object-position: 0,
        object-fit: contain,
        max-height: 318px,
        aspect-ratio: 150 / 159,
      ),
    )
  );

  &Wrapper {
    position: relative;
  }
}
