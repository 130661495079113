@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  position: relative;
  padding: 56px 0;
  box-sizing: border-box;
  height: 100%;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(56) 0,
      ),
      md: (
        padding: 32px 0 56px,
      ),
      sm: (
        padding: 24px 0 56px,
      ),
    )
  );

  &.withoutTheme {
    background-color: unset;
  }

  &.whiteText {
    .title,
    .description {
      color: var.$gray-0;
    }
  }
}

.background {
  &Wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 0;
  }

  picture {
    padding: 0;
    display: block;
    line-height: 0;
  }
}

.wrapper {
  position: relative;

  @include mix.media(
    (
      sm: (
        height: 100%,
      ),
    )
  );
}

.grid {
  display: grid;
  grid-template-columns: 800px 1fr;

  @include mix.media(
    (
      1280: (
        grid-template-columns: mix.vw(800) 1fr,
      ),
      md: (
        grid-template-columns: 512px 1fr,
      ),
      sm: (
        grid-template-columns: 100%,
        height: 100%,
      ),
    )
  );
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 4;
  min-height: 256px;
  box-sizing: border-box;

  @include mix.media(
    (
      1280: (
        min-height: 256px,
      ),
      md: (
        min-height: 270px,
      ),
      sm: (
        min-height: 280px,
      ),
    )
  );

  br {
    @include mix.media(
      (
        md: (
          display: none,
        ),
      )
    );
  }
}

.title {
  @include mix.TitleH1;
}

.description {
  @include mix.TextB1Regular;
  margin: 16px 0 32px;

  @include mix.media(
    (
      1280: (
        margin: mix.vw(16) 0 mix.vw(32),
      ),
      md: (
        margin: 16px 0 32px,
      ),
    )
  );
}

.button {
  margin-top: auto;

  @include mix.media(
    (
      sm: (
        width: 100%,
      ),
    )
  );
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @include mix.media(
    (
      1280: (
        object-position: left 0,
      ),
      md: (
        object-position: 0,
        object-fit: contain,
      ),
    )
  );

  &Wrapper {
    position: absolute;
    top: -56px;
    bottom: -56px;
    right: 0;
    width: 453px;

    @include mix.media(
      (
        1280: (
          top: mix.vw(-56),
          bottom: mix.vw(-56),
          width: mix.vw(453),
        ),
        md: (
          top: -32px,
          bottom: -56px,
          width: 453px,
          transform: translateX(50%),
        ),
      )
    );
  }
}

.videoBg {
  position: absolute;
  padding: 0;
  z-index: 2;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;
}

.videoClassName {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 0;
}
