@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.card {
  width: 280px;
  position: relative;
  z-index: 0;
  height: 100%;

  @media (width <= 1216px) {
    width: auto;
  }
}

.title {
  color: ste.$sys-neutral-text-main;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.button {
  width: 100%;
}

.img {
  position: absolute;
  object-fit: cover;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
