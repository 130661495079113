@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element' as ste;

.content {
  display: grid;
  grid-template-columns: 352px 1fr;
  gap: 32px;

  box-sizing: border-box;
  height: 100%;
  padding: 32px;

  background: ste.$sys-neutral-background;

  &[data-layout-type='tablet'],
  &[data-layout-type='mobile'] {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  &[data-layout-type='mobile'] {
    padding: 24px;
    gap: 24px;
  }
}

.author {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ste.$sys-neutral-text-main;

  &[data-layout-type='mobile'] {
    gap: 8px;
  }
}
