@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.card {
  width: 280px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  box-sizing: border-box;
  transition: all 0.3s;
  box-shadow: 0px 4px 32px 0px #2222220d;

  @media (max-width: 828px) {
    height: 550px;
  }

  @media (max-width: 768px) {
    height: 600px;
  }

  &Kuber {
    @media (max-width: 828px) {
      height: 600px;
    }

    @media (max-width: 768px) {
      height: 700px;
    }
  }

  &Hovered {
    border: 2px solid #26d07c;
  }

  &Description {
    @include mix.TextB2Regular;
    color: #595959;
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: 1160px) {
      -webkit-line-clamp: 3;
      line-clamp: 3;
      height: 50px;
    }

    @media (max-width: 828px) {
      -webkit-line-clamp: 2;
      line-clamp: 2;
      height: 40px;
    }
  }

  &Info {
    display: grid;
    gap: 16px;

    &Kuber {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &Title {
      color: #4e4e4e;
      @include mix.TextB2Regular;
    }

    &Subtitle {
      @include mix.TextB1Semibold;
      font-size: 16px;
    }
  }

  &Row {
    display: grid;
    gap: 2px;
  }

  &Price {
    display: grid;
    gap: 4px;
  }

  &Check {
    display: flex;
    align-self: center;
    gap: 9px;
    @include mix.TextB2Regular;

    &Icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      color: #fff;

      opacity: 0;

      &Checked {
        opacity: 1;
      }

      &Wrapper {
        position: relative;

        display: block;
        flex-shrink: 0;
        cursor: pointer;

        box-sizing: border-box;
        width: 20px;
        height: 20px;

        border: 2px solid var.$graphite-50;

        &Checked {
          background: var.$green-main-100;
          border-color: var.$green-main-100;
        }

        @include mix.media(
          (
            1280: (
              width: mix.vw(20),
              height: mix.vw(20),
            ),
            md: (
              width: 20px,
              height: 20px,
            ),
          )
        );

        &::before {
          content: '';

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;

          width: 32px;
          height: 32px;

          border-radius: 76px;

          transition: background 0.3s ease;

          @include mix.media(
            (
              1280: (
                width: mix.vw(32),
                height: mix.vw(32),
              ),
              md: (
                width: 32px,
                height: 32px,
              ),
            )
          );
        }
      }
    }
  }
}

.connect {
  max-width: 100%;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1;
}

.tag {
  background-color: #dfdfdf;
  padding: 4px 8px;
  @include mix.TextB2Regular;
  transition: all 0.3s;
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
  max-height: 28px;
  box-sizing: border-box;
  z-index: 1;

  &Hovered {
    background-color: #f0d559;
  }

  &Text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.hintClassName {
  width: 300px;
}

.slide {
  transform: none !important;
}

.kuberLandingPage {
  @media (max-width: 828px) {
    min-width: 240px;
    width: 100%;
    max-width: 280px;

    min-height: 550px;
    height: 100%;
    max-height: 750px;
  }

  @media (max-width: 768px) {
    min-height: 60px;
    height: 100%;
    max-height: 750px;
  }
}
