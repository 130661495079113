@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.price {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 8px;
  overflow: hidden;
  color: ste.$sys-neutral-text-main;

  &Free {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &Title {
    display: flex;
    gap: 8px;
    color: ste.$sys-neutral-text-main;

    @media (max-width: 1000px) {
      flex-wrap: wrap;
      gap: 2px;
      margin-bottom: 4px;
    }

    @media (max-width: 828px) {
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 0;
    }
  }

  &Tag {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    background-color: #f5ffbd;
    max-height: 24px;
  }

  &Period {
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;

    &Text {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media (max-width: 1100px) {
      padding-top: 0;
    }

    @media (max-width: 768px) {
      padding-top: 8px;
    }

    &Discount {
      @include mix.TextCaptionRegular;
      text-decoration: line-through;
      color: var.$graphite-50;
    }
  }
}
