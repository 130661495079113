@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  display: block;
  position: relative;
  padding: 32px;
  background: var.$gray-0;
  box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.03),
    0 4px 32px 0 rgba(34, 34, 34, 0.05);
  box-sizing: border-box;
  height: 100%;
  transition: background 0.3s ease;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(32),
      ),
      md: (
        padding: 32px,
      ),
      sm: (
        padding: 24px,
      ),
    )
  );

  &:hover {
    background: var.$green-main-50;

    .icon {
      color: var.$graphite-100;
    }
  }
}

.image {
  &Wrapper {
    margin-bottom: 24px;
    width: 138px;
    height: 72px;

    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(24),
          width: mix.vw(138),
          height: mix.vw(72),
        ),
        md: (
          margin-bottom: 24px,
          width: 138px,
          height: 72px,
        ),
      )
    );

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.text {
  @include mix.TextB1Regular;
  font-weight: 600;
}

.icon {
  @include mix.media(
    (
      1280: (
        top: mix.vw(16),
        right: mix.vw(16),
      ),
      md: (
        top: 16px,
        right: 16px,
      ),
    )
  );
  top: 16px;
  right: 16px;
  position: absolute;
  color: var.$green-main-100;
  transition: color 0.3s ease;
}
