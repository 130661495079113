@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  position: relative;

  :global(.swiper-pagination) {
    bottom: 0;
    line-height: 0;
  }
}

.button {
  pointer-events: all;
  cursor: pointer;

  display: flex;

  margin-left: auto;
  padding: 0;

  background: transparent;
  border: none;

  &Left {
    transform: rotate(-180deg);
    margin: 0;
  }
}

.buttons {
  pointer-events: none;

  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);

  display: flex;

  width: 100%;

  &Vertical {
    .button {
      transform: rotate(90deg);

      &Left {
        transform: rotate(-90deg);
      }
    }
  }
}

.arrow {
  width: 40px;
  height: 40px;

  @include mix.media(
    (
      1280: (
        width: mix.vw(40),
        height: mix.vw(40),
      ),
    )
  );

  color: var.$graphite-60;
}

.progressbar {
  transform: rotate(-180deg);
  height: 4px;
  background: #06bd7b;

  &Inner {
    height: 100%;
    background: #e9e9ea;
  }
}

.bullet {
  @include mix.media(
    (
      1280: (
        width: mix.vw(8),
        height: mix.vw(8),
      ),
      md: (
        width: 8px,
        height: 8px,
      ),
    )
  );

  width: 8px;
  height: 8px;

  opacity: 1;
  background: var.$graphite-10;
  border-radius: 0;

  transition: background 0.3s ease-in-out, width 0.3s ease-in-out;

  &:hover {
    background: var.$graphite-60;
  }

  &:global(.swiper-pagination-bullet-active) {
    @include mix.media(
      (
        1280: (
          width: mix.vw(30),
        ),
        md: (
          width: 30px,
        ),
      )
    );

    width: 30px;
    background: var.$graphite-60;
  }
}
