@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.main {
  max-width: 904px;

  @media (width <= 1216px) {
    max-width: 100%;
  }
}

.product {
  max-width: 1216px;
}

.desktop {
  display: block;

  @media (width <= 1216px) {
    display: none;
  }
}

.large {
  display: none;

  @media (width <= 1216px) {
    display: block;
  }

  @media (width <= 1052px) {
    display: none;
  }
}

.medium {
  display: none;

  @media (width <= 1052px) {
    display: block;
  }

  @media (width <= 500px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (width <= 500px) {
    display: block;
  }
}
