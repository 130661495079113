@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.snackBenefitsCard {
  > svg {
    // TODO: hack for custom icons
    height: 48px !important;
    width: 48px !important;
    flex-shrink: 0;
  }
}

.root {
  padding: 56px 0;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(56) 0,
      ),
      md: (
        padding: 40px 0,
      ),
      sm: (
        padding: 32px 0,
      ),
    )
  );
}

.wrapper {
  @include mix.media(
    (
      sm: (
        padding: 0 16px,
      ),
    )
  );
}

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-width: 1008px;
  margin-bottom: 40px;

  @include mix.media(
    (
      1280: (
        margin-bottom: mix.vw(40),
        max-width: mix.vw(1008),
        gap: mix.vw(16),
      ),
      md: (
        margin-bottom: 32px,
        max-width: 100%,
        gap: 16px,
      ),
      sm: (
        margin-bottom: 24px,
      ),
    )
  );

  &:empty {
    display: none;
  }
}

.title {
  @include mix.TitleH2;
}

.listItems {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;

  &.oneColumn {
    grid-template-columns: 100%;
  }

  &.twoColumns {
    grid-template-columns: repeat(2, 1fr);
  }

  &.threeColumns {
    grid-template-columns: repeat(3, 1fr);
  }

  &.fourColumns {
    grid-template-columns: repeat(4, 1fr);
  }

  &.twoColumns,
  &.threeColumns,
  &.fourColumns {
    gap: 32px;

    @include mix.media(
      (
        1280: (
          gap: mix.vw(32),
        ),
        md: (
          grid-template-columns: 100%,
          gap: 32px,
        ),
      )
    );
  }

  &.smallGap {
    @include mix.media(
      (
        md: (
          gap: 16px,
        ),
      )
    );
  }

  &.numericList {
    counter-reset: card;
  }

  &.mediumTwoColumns {
    @include mix.media(
      (
        md: (
          grid-template-columns: repeat(2, 1fr),
          gap: 16px,
        ),
        sm: (
          grid-template-columns: 100%,
        ),
      )
    );
  }
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 40px;

  @include mix.media(
    (
      1280: (
        margin-top: mix.vw(40),
        gap: mix.vw(40),
      ),
      md: (
        margin-top: 32px,
        gap: 16px,
      ),
      sm: (
        margin-top: 24px,
      ),
    )
  );
}
