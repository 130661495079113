@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.wrapperMainPage {
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (width <= 1216px) {
    padding: 0px 40px;
    gap: 10px;
  }

  @media (width <= 768px) {
    padding: 0 16px;
  }
}

.main {
  display: flex;
  gap: 32px;

  @media (width <= 1216px) {
    flex-direction: column;
    touch-action: pan-y;
  }
}

.wrapperProductPage {
  padding-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 1216px;
  margin: 0 auto;

  @media (width <= 1216px) {
    padding: 0px 40px;
  }

  @media (width <= 768px) {
    padding: 0px 16px;
  }
}

.content {
  max-width: 1216px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0;
  background-color: ste.$sys-neutral-background;

  @include mix.media(
    (
      1280: (
        padding: mix.vw(40) 0,
      ),
      md: (
        padding: 40px 0,
      ),
      sm: (
        padding: 32px 0,
      ),
    )
  );
}

.title {
  @include mix.TitleH2;

  @media (width <= 768px) {
    margin-bottom: 16px;
  }
}

.disclaimer {
  display: block;

  @media (width <= 1216px) {
    display: none;
  }

  &Mobile {
    display: none;

    @media (width <= 1216px) {
      display: block;
    }
  }
}

.aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
}
