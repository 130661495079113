@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  :global(.swiper-pagination) {
    bottom: 24px;
    line-height: 0;
  }

  :global(.swiper-slide) {
    @include mix.media(
      (
        sm: (
          height: auto,
        ),
      )
    );
  }
}
