@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element' as ste;

.root {
  position: relative;
  padding-bottom: 56.5%;
  border: 0;

  &WithError {
    padding: 0;
  }

  & > * {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
    border-radius: inherit;
  }
}

.playerError {
  position: relative;
  overflow: scroll;
  background: #f8f8fa;
  border-radius: 24px;

  &Description {
    @include mix.media(
      (
        1280: (
          padding: mix.vw(55),
        ),
        md: (
          padding: 32px 54px,
        ),
      )
    );

    padding: 55px;
    text-align: center;
  }

  &Title {
    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(16),
          font-size: mix.vw(170),
          line-height: mix.vw(170),
        ),
        md: (
          margin-bottom: 24px,
          font-size: 88px,
          line-height: 88px,
        ),
      )
    );

    margin-bottom: 16px;

    font-size: 170px;
    font-weight: 300;
    line-height: 170px;

    background: radial-gradient(
      45.71% 123.24% at 22.89% 23.1%,
      #64a2ff 0%,
      #07e897 84.9%
    );
    background-clip: text;

    -webkit-text-fill-color: transparent;
  }

  &Subtitle {
    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(8),
          font-size: mix.vw(32),
          line-height: mix.vw(36),
        ),
        md: (
          margin-bottom: 16px,
          font-size: 24px,
          line-height: 28px,
        ),
      )
    );

    margin-bottom: 8px;

    font-size: 32px;
    line-height: 36px;
    color: #212830;
    letter-spacing: -0.01em;
  }

  &Note {
    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(32),
          font-size: mix.vw(18),
          line-height: mix.vw(26),
        ),
        md: (
          margin-bottom: 24px,
          font-size: 18px,
          line-height: 26px,
        ),
      )
    );

    margin-bottom: 32px;
    font-size: 18px;
    line-height: 26px;
    color: #7a7e83;
  }

  &ListLink {
    width: fit-content;
    margin: 0 auto;
    padding: 0;

    text-align: left;
    list-style: none;
  }

  &ListItem {
    @include mix.media(
      (
        1280: (
          margin-bottom: mix.vw(16),
        ),
        md: (
          margin-bottom: 16px,
        ),
      )
    );

    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &ListItemLink {
    display: inline-flex;
    gap: 6px;
    align-items: center;

    font-size: 18px;
    line-height: 26px;
    color: #06bd7b;
    text-decoration: none;
  }
}

.videoButton {
  width: 104px;
  height: 104px;

  @include mix.media(
    (
      1280: (
        width: mix.vw(104),
        height: mix.vw(104),
      ),
      md: (
        width: 64px,
        height: 64px,
      ),
    )
  );

  &Wrapper {
    cursor: pointer;

    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 100%;
      width: 100%;
      display: block;
      background: var.$graphite-100;
      opacity: 0.5;
      z-index: 1;
    }
  }
}

.custom {
  &Error {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;

    background-color: ste.$sys-blackout;

    @include mix.media(
      (
        1280: (
          gap: mix.vw(16),
        ),
        md: (
          gap: 12px,
        ),
        sm: (
          gap: 10px,
        ),
      )
    );

    &Title {
      font-size: 48px;
      line-height: 56px;
      color: #fff;
      letter-spacing: -0.96px;

      @include mix.media(
        (
          1280: (
            font-size: mix.vw(48),
            line-height: mix.vw(56),
          ),
          md: (
            font-size: 32px,
            line-height: 36px,
          ),
          sm: (
            font-size: 24px,
            line-height: 28px,
            letter-spacing: -0.24px,
          ),
        )
      );
    }

    &Desc {
      font-size: 20px;
      line-height: 32px;
      color: #fff;
      opacity: 0.6;

      @include mix.media(
        (
          1280: (
            font-size: mix.vw(20),
            line-height: mix.vw(32),
          ),
          md: (
            font-size: 16px,
            line-height: 24px,
          ),
          sm: (
            font-size: 14px,
            line-height: 20px,
          ),
        )
      );
    }
  }
}

.videoError {
  &[data-test-id="video-player"] {
    padding-bottom: 0;
  }
}
