@use 'src/assets/scss/core/mixins' as mix;

.text {
  @include mix.media(
    (
      1280: (
        font-size: mix.vw(12),
        line-height: mix.vw(16),
      ),
      md: (
        font-size: 12px,
        line-height: 16px,
      ),
    )
  );

  font-size: 12px;
  line-height: 16px;
  color: #bcbfc1;
  margin: 0;
  padding-top: 16px;
}
