@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;
@use '@sbercloud/figma-tokens-web/build/scss/components/styles-tokens-element'
  as ste;

.products {
  display: flex;
  position: relative;
  height: fit-content;
}

.tabs {
  display: none;

  @media (width <= 1216px) {
    display: block;
    width: 100%;
    overflow: hidden;
  }
}

.divider {
  position: absolute;
  left: -1px;
  height: 100%;

  @media (width <= 1216px) {
    display: none;
  }
}

.product {
  padding: 12px 16px;
  @include mix.TextB1Regular;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 3px solid transparent;

  &:hover {
    border-bottom: 3px solid #d3d3d3;
  }
}

.active {
  border-bottom: 3px solid #26d07c;
  &:hover {
    border-bottom: 3px solid #26d07c;
  }
  @include mix.TextB1Semibold;
}

.list {
  display: block;
  height: fit-content;

  @media (width <= 1216px) {
    display: none !important;
  }
}

.item {
  display: flex;
  gap: 8px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.desc {
  color: ste.$sys-neutral-text-support;
}

.title {
  color: ste.$sys-neutral-text-main;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.img {
  min-width: 24px;
  height: 24px;
}
