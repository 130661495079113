@use 'src/assets/scss/core/variables' as var;
@use 'src/assets/scss/core/mixins' as mix;

.root {
  background: #fff;
}

.footer {
  @include mix.media(
    (
      1280: (
        padding-bottom: mix.vw(112),
      ),
      md: (
        padding-bottom: 56px,
      ),
      sm: (
        padding-bottom: 64px,
      ),
    )
  );

  padding-bottom: 112px;
}

.benefits {
  :global(.link) {
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var.$green-main-100;
      text-decoration: none;
    }
  }
}

.clientCarouselWrapper {
  padding-bottom: 40px;

  @include mix.media(
    (
      1280: (
        padding-bottom: mix.vw(40),
      ),
      md: (
        padding-bottom: 40px,
      ),
      sm: (
        padding-bottom: 32px,
      ),
    )
  );
}

.showMore {
  margin: 0 auto;
  display: block;
  width: fit-content;
  text-align: center;

  @include mix.media(
    (
      md: (
        width: 100%,
      ),
    )
  );
}

.buttonWrapper {
  margin-top: 40px;

  @include mix.media(
    (
      1280: (
        margin-top: mix.vw(40),
      ),
      md: (
        margin-top: 32px,
      ),
      sm: (
        margin-top: 24px,
      ),
    )
  );
}
